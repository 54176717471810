import axios from 'axios';
import React, { useState } from 'react';

const Business = ({ getUserBusiness, userInfo, changeSuccess }) => {
    const getDate = new Date(getUserBusiness.createdAt);
    const year = getDate.getFullYear();
    const month = getDate.getMonth() + 1;
    const day = getDate.getDay();

    // 👍 클릭 시 서버 통신
    const clickSucces = () => {
        axios
            .post(
                `${process.env.REACT_APP_API_URL}/business/success`,
                {
                    businessId: getUserBusiness._id,
                },
                { withCredentials: true },
            )
            .then((res) => {
                if (res.data.code === 200) {
                    // 잘 동작했다면 reload 진행
                    changeSuccess();
                    console.log('seccess 성공!');
                }
            });
    };
    return (
        <div className="userBusiness">
            <div className="userProfileP">
                {userInfo.image ? (
                    <img src={userInfo.image} className="userProfile" alt="profile"></img>
                ) : (
                    <div className="userProfile"></div>
                )}
            </div>

            <div className="userAndBusinessInfo">
                <p className="nickname">{userInfo.nickname}</p>
                <p className="date">
                    {year}.{month}.{day}
                </p>
                <p className="context">{getUserBusiness.context}</p>
                <p className="success" onClick={clickSucces}>
                    👍 {getUserBusiness.success}
                </p>
            </div>
        </div>
    );
};

export default Business;
