import React from 'react';
import RecomRes from "./RecomRes";

const RecomResUser = () => {
    return(
        <>
            <RecomRes type='user'/>
        </>
        
    )
}

export default RecomResUser;