import axios from 'axios';
import React, { useEffect, useState } from 'react';
import BusinessShow from '../business/BusinessShow';
import '../business/Business.css';

const BusinessAll = () => {
    const [businessData, setBusinessData] = useState();
    const [btnSeccess, setBtnSeccess] = useState(false);
    const [click, setClick] = useState({ type: '', category: '' });
    const change = () => {
        setBtnSeccess(!btnSeccess);
    };
    useEffect(() => {
        axios
            .get(`${process.env.REACT_APP_API_URL}/business/show`, {
                params: {
                    page: 1,
                    type: click.type,
                    category: click.category,
                },
            })
            .then((res) => {
                console.log(res);
                setBusinessData(res.data.info.pagination);
            })
            .catch((err) => {
                console.log(err);
            });
    }, [click]);

    const [typeBtn] = useState(['웹툰', '소설']);
    const [categoryBtn] = useState(['코미디', '무협', '스포츠', '로맨스', '현대', '판타지']);
    const handleClick = (type, category) => {
        if (type) {
            setClick({ ...click, type: type });
        }

        if (type === click.type) {
            setClick({ ...click, type: '' });
        }

        if (category) {
            setClick({ ...click, category: category });
        }

        if (category === click.category) {
            setClick({ ...click, category: '' });
        }
    };

    return (
        <div>
            <div className="typeCategory">
                <div className="typeAll">
                    {typeBtn.map((i, index) => (
                        <span
                            key={index}
                            onClick={() => handleClick(i, undefined)}
                            className={'btn' + (click.type === i ? 'Active' : '')}
                        >
                            {i}
                        </span>
                    ))}
                </div>
                <div className="categoryAll">
                    {categoryBtn.map((i, index) => (
                        <span
                            key={index}
                            onClick={() => handleClick(undefined, i)}
                            className={'btn' + (click.category === i ? 'Active' : '')}
                        >
                            {i}
                        </span>
                    ))}
                </div>
            </div>
            <BusinessShow businessData={businessData} onClick={change} />
        </div>
    );
};

export default BusinessAll;
