import React from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import '../main/Main.css';
import '../main/TryTryTry.css';

const BusinessShow = ({ businessData, onClick }) => {
    console.log('businessData: ', businessData);
    // 시간을 계산해주는 함수
    // ex) 1시간 전, 3일 전, 2달전, 1년전
    const timeForToday = (value) => {
        const end = new Date();
        const start = new Date(value);

        const diff = (end - start) / 1000;

        const times = [
            { name: '년', milliSeconds: 60 * 60 * 24 * 365 },
            { name: '개월', milliSeconds: 60 * 60 * 24 * 30 },
            { name: '일', milliSeconds: 60 * 60 * 24 },
            { name: '시간', milliSeconds: 60 * 60 },
            { name: '분', milliSeconds: 60 },
        ];

        for (const value of times) {
            const betweenTime = Math.floor(diff / value.milliSeconds);

            if (betweenTime > 0) {
                return `${betweenTime}${value.name} 전`;
            }
        }
        return '방금 전';
    };

    // '영업중' or '영업완료'를 누를 경우
    const spanButtonClick = (id) => {
        axios
            .post(
                `${process.env.REACT_APP_API_URL}/business/success`,
                {
                    businessId: id,
                },
                { withCredentials: true },
            )
            .then((res) => {
                if (res.data.code === 200) {
                    onClick();
                }
            });
    };
    return (
        <div>
            {businessData &&
                businessData.map((list) => {
                    return (
                        <div className="listAll">
                            <div className="oneB">
                                <div className="userProfileP">
                                    {list.user.image ? (
                                        <img src={list.user.image} className="userProfile" alt="profile"></img>
                                    ) : (
                                        <div className="userProfile"></div>
                                    )}
                                </div>
                                <div className="spanThree">
                                    <span>{list.user.nickname}</span>님이
                                    <span className="spanTitle">
                                        <Link
                                            to={`/business/${list.data.workId}`}
                                            style={{ 'text-decoration': 'none', color: ' #76abff' }}
                                        >
                                            {list.work.title}
                                        </Link>
                                    </span>
                                    을 영업하셨습니다.
                                    <span className="spanTime"> {timeForToday(list.data.createdAt)}</span>
                                    <button
                                        className="spanButton"
                                        onClick={() => {
                                            spanButtonClick(list.data._id);
                                        }}
                                    >
                                        👍 {list.data.success}
                                    </button>
                                </div>
                            </div>
                            <div className="twoB">
                                {list.work.image ? (
                                    <img src={list.work.image} className="workProfile" alt="workProfile"></img>
                                ) : (
                                    <div className="workProfile"></div>
                                )}

                                <div className="workContext">{list.data.context}</div>
                            </div>
                        </div>
                    );
                })}
        </div>
    );
};

export default BusinessShow;
