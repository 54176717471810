import React, { useState, useCallback, useEffect } from 'react';
import axios from "axios";
import debounce from 'lodash.debounce';
/**
 * 타래 검색
 */
const ThreadSearch = ({ id, listType, setThreadInfo }) => {
    const [searchTerm, setSearchTerm] = useState('');

    const debouncedSearch = useCallback(debounce((query) => {
        axios.get(`${process.env.REACT_APP_API_URL}/thread/search`, {
            params: {
                work: id,
                type: listType,
                search: query
            },
            withCredentials: true
        }).then((res) => {
            setThreadInfo(res.data.threads)
        }).catch((error) => {
            console.error('Search failed:', error);
        });
    }, 500), [id, listType]);

    // 검색어가 변경될 때마다 호출되는 함수
    const handleSearch = (e) => {
        const query = e.target.value;
        setSearchTerm(query);
        debouncedSearch(query);
    };

    // 컴포넌트가 언마운트될 때 디바운스 함수 취소
    useEffect(() => {
        return () => {
            debouncedSearch.cancel();
        };
    }, [debouncedSearch]);



    return (
        <div className="search-input-container">
            <input
                type="text"
                className="search-input"
                value={searchTerm}
                onChange={handleSearch}
                placeholder="검색"
            />
        </div>
    )
}

export default ThreadSearch;