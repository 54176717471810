import React from 'react';
import AuthForm from './AuthForm';

const Password = () => {
    return (
        <div>
            <AuthForm type='password' />
        </div>
    );
};

export default Password;