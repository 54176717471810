import React from 'react';
import AuthForm from './AuthForm';

const Signin = () => {
    return (
        <div>
            <AuthForm type='signin' />
        </div>
    );
};

export default Signin;