import React, { useState, useRef } from 'react';
import axios from 'axios';

/**
 * Thread.js >
 * 스레드 저장
 */
const ThreadSave = ({ id }) => {
    const [threadInfo, setThreadInfo] = useState({
        round: '',
        contents: '',
    });

    const roundRef = useRef(null); // 회차 입력 필드 참조
    const contentsRef = useRef(null); // 코멘트 입력 필드 참조

    const onChange = (name, value) => {
        setThreadInfo({
            ...threadInfo,
            [name]: value,
        });
    };

    const sendThreadInfo = () => {
        axios
            .post(
                `${process.env.REACT_APP_API_URL}/thread`,
                {
                    round: threadInfo.round,
                    contents: threadInfo.contents,
                    workId: id,
                },
                { withCredentials: true },
            )
            .then(() => {
                setThreadInfo({
                    round: '',
                    contents: '',
                });
                window.location.reload();
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const handleInput = (e, name) => {
        const value = e.target.textContent;
        onChange(name, value);
        moveCaretToEnd(e.target); // 커서를 항상 끝으로 이동
    };

    const moveCaretToEnd = (el) => {
        const range = document.createRange();
        const selection = window.getSelection();
        range.selectNodeContents(el);
        range.collapse(false); // 커서를 텍스트 끝으로 이동
        selection.removeAllRanges();
        selection.addRange(range);
    };

    const isRoundFilled = threadInfo.round.trim() !== '';

    return (
        <div className="inputs">
            <div className="form-container">
                <div
                    ref={roundRef}
                    className="input-placeholder"
                    contentEditable="true"
                    data-placeholder="회차를 입력해주세요"
                    onInput={(e) => handleInput(e, 'round')}
                    suppressContentEditableWarning={true}
                    style={{
                        borderBottom: threadInfo.round.trim() !== '' ? '1px solid #333' : '1px solid #ccc',
                    }}
                >
                    {threadInfo.round}
                </div>
                <div
                    ref={contentsRef}
                    className="input-placeholder"
                    contentEditable="true"
                    data-placeholder="코멘트를 남기시다면 나중에 찾아보기 좋아요"
                    onInput={(e) => handleInput(e, 'contents')}
                    suppressContentEditableWarning={true}
                    style={{
                        borderBottom: threadInfo.contents.trim() !== '' ? '1px solid #333' : '1px solid #ccc',
                    }}
                >
                    {threadInfo.contents}
                </div>
                <button
                    className="styled-button"
                    onClick={sendThreadInfo}
                    disabled={!isRoundFilled}
                    style={{
                        color: isRoundFilled ? '#76ABFF' : '#ccc',
                        cursor: isRoundFilled ? 'pointer' : 'not-allowed',
                    }}
                >
                    저장
                </button>
            </div>
        </div>
    );
};

export default ThreadSave;
