import React from 'react';
import './News.css';

const News = ({newsData}) => {
    console.log("newsData: ", newsData)

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return `${date.getFullYear()}.${String(date.getMonth() + 1).padStart(2, '0')}.${String(date.getDate()).padStart(2, '0')}`;
    };

    return (
        <div>
            <div className="report">제보하기</div>
            <div className="bigOne">
                {newsData && newsData.map((data) => {
                    return (
                        <div className="newsOne">
                            <div className="top">
                                <div className="emoji">{data.emoji}</div>
                                <div className="title">{data.title}</div>
                                <div className="date">{formatDate(data.createdAt)}</div>
                            </div>
                            <div className="context">{data.content}</div>
                        </div>
                    )
                })}
            </div>
        </div>
    );
};

export default News;
