import Thread from "./Thread"

/**
 * 타래 전체 보기
 */
const ThreadAll = () => {
    return (
        <div>
            <Thread type='all'/>
        </div>
    )
}

export default ThreadAll;