import { useEffect, useState } from "react";
import './Main.css';
import axios from "axios";
import RecomDetail from "../../recom/RecomDetail";
import TopAndInput from "../TopAndInput";

const MainRecom = () => {
    const [filterType, setFilterType] = useState('웹툰'); 

    /**
     * 랜덤한 5개의 추천 데이터를 가져오는데 필요한 것들
     * 1. 랜덤한 5개의 추천 데이터를 담을 변수
     * 2. 백엔드와 통신하는 함수
     * 3. '다른 추천'이라는 버튼을 눌렀을 때의 함수
     */
    // 1. 랜덤한 5개의 추천 데이터를 담을 변수
    const [randomData, setRandomData] = useState([])
    // 2. 백엔드와 통신하는 함수
    // 본래 useEffect()에 있었는데 버튼 눌렀을 때 다시 호출해야해서 함수로 뺌
    const fetchRandomData = () => {
        axios.get(`${process.env.REACT_APP_API_URL}/recom/random`).then((res) => {
            setRandomData(res.data.data);
            console.log("randomData: ", res.data.data);
        });
    };
    // 3. '다른 추천'이라는 버튼을 눌렀을 때의 함수
    const handleAnotherRecommendation = () => {
        fetchRandomData(); 
    };
    

    /**
     * 모달을 통해 추천 디테일 보기
     * 1. 모달 여는 변수
     * 2. div 하나를 선택할 때의 데이터를 담는 변수
     * 3. div 하나를 선택할 때의 함수
     */
    // 1. 모달 여는 변수
    const [isModalOpen, setIsModalOpen] = useState(false);
    // 2. div 하나를 선택할 때의 데이터를 담는 변수
    const [oneRandomData, setOneRandomData] = useState();
    // 3. div 하나를 선택할 때의 함수
    const handleDetailClick = (data) => {
        setOneRandomData(data);
        setIsModalOpen(true);
    };

    /**
     * 웹툰과 웹소설 대로 데이터를 필터링하기
     * 1. 웹툰과 웹소설 각각 눌렀을 때 발동하는 함수
     * 2. 필터링된 데이터 생성
     */
    // 1. 웹툰과 웹소설 각각 눌렀을 때 발동하는 함수
    const handleFilterChange = (type) => {
        setFilterType(type);
    };
    // 2. 필터링된 데이터 생성
    // 그 전까지는 randomData로 화면 보여줌
    const filteredData = randomData.filter(data => data.id.type === filterType);

    useEffect(() => {
        fetchRandomData();
    }, [])

    return (
        <div className="mainrecom-body">
            <TopAndInput />
            <div className="filter-buttons">
                <button onClick={() => handleFilterChange('웹툰')} className={filterType === '웹툰' ? 'active' : ''} >웹툰</button>
                <button onClick={() => handleFilterChange('웹소설')} className={filterType === '웹소설' ? 'active' : ''} >웹소설</button>
            </div>
            <div>
                {filteredData.map((data, index) => (
                    <div key={index} className="data-row" onClick={() => handleDetailClick(data)}>
                        <div className="data-tag">
                            {data.id.tag.length > 0 ? data.id.tag[Math.floor(Math.random() * data.id.tag.length)] : ''}
                        </div>
                        <div className="data-reason">{data.reason}</div>
                    </div>
                ))}
            </div>
            <div>
                <button className="save-request-button" onClick={handleAnotherRecommendation}>다른 추천</button>
            </div>

            <RecomDetail isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} currentWork={oneRandomData}/>
        </div>
    );
};

export default MainRecom;