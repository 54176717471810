import React from 'react';
import '../thread/Thread.css';
import { Link } from 'react-router-dom';

const ThreadAndWorkExplain = ({ title, id, url, type }) => {
    return (
        <div className="threadTop">
            <p className="threadTopType">{type}</p>
            <Link to={id ? `/business/${id}` : url} style={{ 'text-decoration': 'none' }}>
                <p className="threadTopTitle">{title}</p>
            </Link>
        </div>
    );
};

export default ThreadAndWorkExplain;
