import Thread from "./Thread"

/**
 * 유저 타래 보기
 */
const ThreadUser = () => {
    return (
        <div>
            <Thread type='user' />
        </div>
    )
}

export default ThreadUser;