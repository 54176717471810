import React from 'react';

const MyPage = () => {
    return (
        <div>
            Mypage
        </div>
    );
};

export default MyPage;