import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import './Sign.css'

const AuthForm = ({type}) => {
    const navigate = useNavigate();
    const location = useLocation();
    const from = location.state?.from || '/';  

    const [user, setUser] = useState({
        email: "",
        password: "",
        passwordCheck: ""
    })
    const [userData, setUserData] = useState();
    const [isPassword, setIsPassword] = useState(false);
    const onChange = (e) => {
        setUser({
            ...user,
            [e.target.name]: e.target.value,
        })
        
    }
    useEffect(() => {
        if (user.passwordCheck) {
            setIsPassword(user.password === user.passwordCheck);
        }
    }, [user])
    
    const onClick = (e) => {
        setUserData(user);
        setUser({
            email: "",
            password: "",
            passwordCheck: ""
        })
    }

    useEffect(() => {
        if(userData) {
            axios.post(`${process.env.REACT_APP_API_URL}/user/${type}`, {   
                email : userData.email, 
                password: userData.password,
                birth: birthdate,
                gender: gender,
            },{ withCredentials: true }).then((res) => {
                if(res.status === 200) {
                    if(type === 'signup') {
                        // setSignupSusses(true)
                        window.location.replace("/signin")
                    }
                    if(type === 'signin') {
                        sessionStorage.setItem('user', true)
                        navigate(from, { replace: true });
                    }
                    if(type === 'password') {
                        window.location.replace("/signin")
                    }
                    
                }
            }).catch((err) => {
                alert("실패하였습니다.")
                console.log(err)
            });
            setUserData('');
        }
    }, [userData])

    // 회원가입 클릭시
    const goReplace = (e) => {
        type === 'signup' ? window.location.replace("/signin") : window.location.replace("/signup");
    }
    // 비밀번호 찾기 클릭시
    const changePassword = () => {  
        window.location.replace("/password");
    }
    

    // 회원가입 혹은 비밀번호 찾기에 인증하기를 누를 경우
    const [certification, setCertification] = useState(false)
    const onClickCertification = () => {
        console.log("type: ", type)
        setCertification(true)
        // BS에 인증메일 요청하는 API 보내기
        axios.post(`${process.env.REACT_APP_API_URL}/user/mail/send`, {
            email: user.email,
            type: type
        },{ withCredentials: true } )
    }

    // 이메일 형식 체크
    const [isEmailValid, setEmailValid] = useState(false)
    const emailCheck = (e) => {
        const emailRegEx = /^[A-Za-z0-9]([-_.]?[A-Za-z0-9])*@[A-Za-z0-9]([-_.]?[A-Za-z0-9])*\.[A-Za-z]{2,3}$/i;
        setEmailValid(emailRegEx.test(e.target.value));
    }

    // 인증번호 체크
    const [isCodeValid, setCodeValid] = useState('')
    const [isCodeResult, setCodeResult] = useState(type === 'signin');
    const codeCheck = (e) => {
        const code = e.target.value;
        setCodeValid(code);
        if(code.length === 6) {
            axios.post(`${process.env.REACT_APP_API_URL}/user/mail/vaild`, {
                code: code,
                type: type
            }, { withCredentials: true }).then((res) => {
                setCodeResult(true)
            }).catch(() => {
                console.log("인증 실패")
            })
        }
    }

    // 출생년도 옵션을 생성하는 함수
    const generateYearOptions = () => {
        const currentYear = new Date().getFullYear();
        const years = [];
        const oldestYear = currentYear - 100; // 최대 100년 이전까지
    
        for (let year = currentYear; year >= oldestYear; year--) {
        years.push(<option key={year} value={year}>{year}</option>);
        }
    
        return years;
    };
    // 월 옵션을 생성하는 함수
    const generateMonthOptions = () => {
        const months = [];
    
        for (let month = 1; month <= 12; month++) {
        months.push(<option key={month} value={month}>{month}</option>);
        }
    
        return months;
    };
    // 일 옵션을 생성하는 함수
    const generateDayOptions = (selectedYear, selectedMonth) => {
        const days = [];
        const dayCount = new Date(selectedYear, selectedMonth, 0).getDate(); // 해당 월의 마지막 날짜 구하기
    
        for (let day = 1; day <= dayCount; day++) {
        days.push(<option key={day} value={day}>{day}</option>);
        }
    
        return days;
    };
    const [birthdate, setBirthdate] = React.useState({
        year: '',
        month: '',
        day: ''
      });

    // 연도, 월, 일 선택 상태 변경 핸들러
    const handleSelectChange = (e) => {
        const { name, value } = e.target;
        setBirthdate(prevState => ({
        ...prevState,
        [name]: value
        }));
    };

    // 성별 상태 초기화
    const [gender, setGender] = useState('');

    // 성별 변경 핸들러
    const handleGenderChange = (e) => {
        setGender(e.target.value);
    };

    return (
        <div>
            
            <>
                    <div>
                        <h3>
                        {type === 'signup' ? '회원가입' : type === 'signin' ? '로그인' : '비밀번호 재설정'}
                        </h3>
                    </div>
                    <div className='inputList'>
                        <input value={user.email} onChange={(e) => {onChange(e); emailCheck(e);}} readOnly={isCodeResult && type !=='signin'} name='email' type="eamil" placeholder='이메일'></input>
                        {type !== 'signin' && 
                             (isCodeResult 
                                ? '' 
                                : (certification 
                                    ? <>
                                        <input value={isCodeValid} onChange={codeCheck} placeholder='인증번호를 입력하세요' />
                                        <p className='pReSendEmail' onClick={onClickCertification}>다시 메일 받기</p>
                                        </> 
                                    : <button onClick={onClickCertification} disabled={!isEmailValid} className={`${isEmailValid ? 'singupBtn' : ''}`}>인증하기</button>
                                ))} 
                        {isCodeResult &&
                            <>
                                <input value={user.password} onChange={onChange} name='password' type="password" placeholder='비밀번호'></input>

                                {isPassword ? <>
                                    {/* <p className='ppp'>추가 정보를 입력해주실 경우 1000포인트를 지급해드립니다.</p>
                                    <select name="성별" class="custom-select" onChange={handleGenderChange} value={gender}>
                                        <option value="" selected disabled>성별</option>
                                        <option value="man">남자</option>
                                        <option value="woman">여자</option>
                                    </select>
                                    <div class="select-container">
                                    <select name="year" onChange={handleSelectChange} value={birthdate.year}>
                                        {generateYearOptions()}
                                    </select>
                                    <select name="month" onChange={handleSelectChange} value={birthdate.month}>
                                        {generateMonthOptions()}
                                    </select>
                                    <select name="day" onChange={handleSelectChange} value={birthdate.day}>
                                        {generateDayOptions(birthdate.year, birthdate.month)}
                                    </select>
                                    </div> */}
                                </> : type !== 'signin' &&
                                    <input value={user.passwordCheck} onChange={onChange} name='passwordCheck' type="password" placeholder='비밀번호 확인'></input>}
                                
                                <button onClick={onClick} disabled={type !== 'signin' && !(user.email && user.password && isPassword)} className={`${isPassword ? 'singupBtn' : ''}`}>
                                    {type === 'signup' ? '회원가입' : type === 'signin' ? '로그인' : '비밀번호 변경'}
                                </button>

                                
                            </>
                        }
                        
                        {type === 'signin' && <div>
                                    <button className='goToSignup' onClick={goReplace}>회원가입</button>
                                    <button className='goToSignup' onClick={changePassword}>비밀번호 찾기</button>
                                </div> }
                        
                    </div>
                </>
            
            
        </div>
    );
};

export default AuthForm;