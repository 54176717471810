import React from 'react';
import { useNavigate } from 'react-router-dom';

import './recom.css';
import { checkAuth } from '../check';

/**
 * 추천 첫 페이지
 */
const Recom = () => {
    const navigate = useNavigate();
    const handleButtonClick = async () => {
        // 사용자가 로그인 되어 있지 않다면 로그인 페이지로 이동
        const isAuthorized = await checkAuth();
        
        console.log("isAuthorized : ", isAuthorized)
        if(!isAuthorized) {
            navigate('/signin', { state: { from: '/recom' }})
        } else {
            navigate('/recom/res')
        }
      };
    return(
        <div className="button-container">
            <div className="text-left-align">
                <div className='arrow-text'><p>뭉치</p>로</div>
                <div className='arrow-text'>추천 리스트</div>
                <div className='arrow-text'>만들기</div>
            </div>
            <div className="action-container"> {/* New container for arrow and button */}
                <div className="arrow-container">
                <img src="/Arrow 2.png" alt="Arrow" />
                </div>
                <button className="request-button" onClick={handleButtonClick}>
                시작
                </button>
            </div>
        </div>   
    )
}

export default Recom