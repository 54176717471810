import axios from 'axios';


export const errorHandler = (err, navigate, type) => {

  if (err.response.data.code === 444) {
    navigate('/signin', { state: { from: type } });
  } else {
    // 다른 에러 처리
  }
};

export const checkAuth = async () => {
    try {
        await axios.get(`${process.env.REACT_APP_API_URL}/user/signin/check`, { withCredentials: true });
        return true; // 성공적으로 응답을 받았을 경우
    } catch (err) {
        console.log(err);
        sessionStorage.clear();
        return false; // 에러가 발생했을 경우
    }
    
}
