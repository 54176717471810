const RecomDetail = ({ isOpen, onClose, currentWork }) => {
    if (!isOpen) return null;
    console.log("currentWork: ", currentWork)
    return (
        <div className="modal" onClick={onClose}>
            <div className="modal-content" onClick={e => e.stopPropagation()}>
                <div className="modal-content-titleAndType">
                    <div className="title-and-dots">
                        <h2 className="modal-content-titleAndType-title">{currentWork.id.title}</h2>
                    </div>
                </div>
                <div className="modal-content-reason">
                    <h4>추천 이유</h4>
                    <p className="modal-content-reason-reason">{currentWork.reason}</p>
                </div>
                <div className="modal-content-tags">
                    <h4>태그</h4>
                    {currentWork.tags.map((tag, index) => (
                        <p key={index} className="modal-content-tags-tag">#{tag}</p>
                    ))}
                </div>
                <div className="modal-content-platform">
                    <h4>플랫폼</h4>
                    {currentWork.id.platform.map((work, index) => (
                        <a key={index} href={work.url} className="modal-content-platform-name">{work.name}</a>
                    ))}
                </div>
                <div>
                    <p>📂 43</p>
                    <p>💙 2</p>
                </div>
            </div>
        </div>
    );
};

export default RecomDetail;