import { useEffect, useState } from "react";
import RecomTop from "./RecomTop";
import axios from "axios";
import { errorHandler } from "../check";
import { useNavigate } from 'react-router-dom';
import RecomSubmit from "./RecomSubmit";

/**
 * 요청했을 경우
 */
const RecomReq = () => {
    const navigate = useNavigate();
    const [lockerList, setLockerList] = useState([]);
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/recom/locker`, { withCredentials: true }).then((res) => {
            console.log("res.data: ", res.data)
            setLockerList(res.data);
        }).catch((err) => errorHandler(err, navigate, '/recom/req'))
    }, [])
    const onClickUrl = (url) => {
        navigate(`/recom/res/${url}`)
    }

    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [itemToDelete, setItemToDelete] = useState(null);

    // Function to open the delete confirmation modal
    const openDeleteModal = (item) => {
        setItemToDelete(item);
        setShowDeleteModal(true);
    };

    // Function to close the delete confirmation modal
    const closeDeleteModal = () => {
        setShowDeleteModal(false);
        setItemToDelete(null);
    };

    const handleDelete = async () => {
        try {
          if (itemToDelete) {
            console.log("itemToDelete: ", itemToDelete)
            await axios.delete(`${process.env.REACT_APP_API_URL}/recom/locker/${itemToDelete.locker.name}`, { withCredentials: true });
            // After successful deletion, refetch the locker list or remove the item from the state
            setLockerList(lockerList.filter(list => list.locker.name !== itemToDelete.locker.name));
            closeDeleteModal();
          }
        } catch (error) {
          errorHandler(error, navigate, '/recom/req');
        }
      };
    
    return (
        <div className="allRecom">
            <RecomTop type='req'/>
            <div className="list-container">
                {lockerList.map((list) => (
                    <div className="req-list-item" onClick={() => onClickUrl(list.locker.url)}>
                        <div className="name">{list.locker.name}</div>
                        <div className="count">{list.matchingFeedbackCount}/{list.numberOfWorks}</div>
                        <div className="req-update" onClick={(e) => {e.stopPropagation(); openDeleteModal(list)}}>삭제하기 {">"}</div> 
                    </div>
                ))}
            </div>
            {showDeleteModal && (
                <div className="modal">
                    <div className="modal-content">
                        <h2>보관함 삭제</h2>
                        <p>정말 보관함을 삭제하실 건가요?</p>
                        <button onClick={handleDelete}>예</button>
                        <button onClick={closeDeleteModal}>아니요</button>
                    </div>
                </div>
            )}
            <RecomSubmit />
        </div>
        
    )
}

export default RecomReq;