import React, { useState, useEffect } from "react";
import axios from 'axios';
import { errorHandler } from "../check";
import { useNavigate, useLocation } from 'react-router-dom';

/**
 * 관리자한테 문의 혹은 평가를 할 수 있는 영역
 */
const RecomSubmit = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const [showOptions, setShowOptions] = useState(false);
    const [showInquiryModal, setShowInquiryModal] = useState(false);
    const [inquiryText, setInquiryText] = useState('');

    // Load the saved inquiry text from localStorage when the component mounts
    useEffect(() => {
        const savedInquiryText = localStorage.getItem('inquiryText');
        if (savedInquiryText) {
            setInquiryText(savedInquiryText);
        }
    }, []);
    // Update localStorage whenever inquiryText changes
    useEffect(() => {
        localStorage.setItem('inquiryText', inquiryText);
    }, [inquiryText]);

    const toggleOptions = () => setShowOptions(!showOptions);

    const openInquiryModal = () => {
        setShowInquiryModal(true);
        toggleOptions(); // Optional: close the options menu
    };

    const handleInquirySubmit = async () => {
        try {
          const response = await axios.post(`${process.env.REACT_APP_API_URL}/admin/inquiry`, {
            inquiryText: inquiryText
          }, {
            withCredentials: true // if you're handling sessions
          });
          console.log(response.data); // Handle the response data as needed
          // Clear the textarea or handle UI changes post submission
          setInquiryText('');
          setShowInquiryModal(false); // Close the modal after submission
        } catch (error) {
            errorHandler(error, navigate, location.pathname)
          // Handle error, could be showing a message to the user
        }
      };

    const handleInquiryChange = (e) => setInquiryText(e.target.value);

    const handleInquiryCancel = () => {
        // Clear the inquiry text from state and localStorage
        setInquiryText('');
        localStorage.removeItem('inquiryText');
        setShowInquiryModal(false); // Close the modal
    };
    const handleEvaluation = () => {
        // Navigate to the provided Google Forms URL
        window.location.href = 'https://forms.gle/R2SxjiFc7B3qBQJX9';
        toggleOptions(); // Optional: close the options menu
      };
    return (
        <div className="fixed-bottom-right">
            <div onClick={toggleOptions} className="circle"></div>
            {/* <img onClick={toggleOptions} src="/loglog.png" /> */}
            {showOptions && (
                <div className="options-container">
                    <div className="option" onClick={openInquiryModal}>문의</div>
                    <div className="option" onClick={handleEvaluation}>평가</div>
                </div>
            )}
            {showInquiryModal && (
                <div className="modal">
                    <div className="modal-content">
                        <h2>문의 남기기</h2>
                        <textarea className="inquiry-textarea" placeholder="문의 사항을 입력해주세요"value={inquiryText} onChange={handleInquiryChange}></textarea>
                        <button onClick={handleInquirySubmit}>완료</button>
                        <button onClick={handleInquiryCancel}>취소</button>
                    </div>
                </div>
            )}
        </div>
        
    )
}

export default RecomSubmit;