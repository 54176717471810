import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import ThreadAndWorkExplain from '../thread/ThreadAndWorkExplain';
import Business from '../work/Business';
import News from './News';

const WorkExplain = () => {
    const { id } = useParams();
    const [workInfo, setWorkInfo] = useState({});
    const [newsOne, setNewsOne] = useState()
    const [newsAll, setNewsAll] = useState()
    useEffect(() => {
        axios
            .get(`${process.env.REACT_APP_API_URL}/work/detail/${id}`)
            .then((res) => {
                setWorkInfo(res.data.info);
            })
            .catch((err) => {
                console.log(err);
            });

        axios
            .get(`${process.env.REACT_APP_API_URL}/news`, {
                params: {
                    id: id,
                    one: false
                }
            }).then((res) => {
                console.log('new data', res.data)
                setNewsOne(res.data.info[0])
                setNewsAll(res.data.info)
            })
    }, [id]);

    /**
     * 영업 글 서버로 전송하기
     */
    const [info, setInfo] = useState('');
    const businessPost = (e) => {
        setInfo(e.target.value);
    };
    const sendInfo = () => {
        axios
            .post(
                `${process.env.REACT_APP_API_URL}/business`,
                {
                    workId: id,
                    context: info,
                },
                { withCredentials: true },
            )
            .then((res) => {
                if (res.data.code === 200) {
                    setInfo('');
                }
            });
    };

    /**
     * 사용자 영업 글 조회하기
     * 영업 글이 존재한다면 input 창은 없어짐
     */
    const [getUserBusiness, setGetUserBusiness] = useState();
    const [userInfo, setUserInfo] = useState();
    const [getBusiness, setGetBusiness] = useState([]);

    const [successReload, setSuccessReload] = useState(false);
    let changeSuccess = () => {
        setSuccessReload(!successReload);
    };
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/business/${id}`, { withCredentials: true }).then((res) => {
            if (res.data.code === 200) {
                setGetUserBusiness(res.data.info);
                setUserInfo(res.data.user);
            }

            if (res.data.code === 201) {
                setGetUserBusiness();
            }
        });
        axios.get(`${process.env.REACT_APP_API_URL}/business/all/${id}`).then((res) => {
            console.log('here ', res.data);
            if (res.data.code === 200) {
                setGetBusiness(res.data.info);
            }
        });
    }, [id, successReload]);

    /**
     * 소식 버튼 누를 경우
     */
    const [isGoToNews, setIsGoToNews] = useState(false);
    let changeGoToNews = () => {
        setIsGoToNews(!isGoToNews);
    };

    return (
        <div className="threadDivAll">
            <ThreadAndWorkExplain title={workInfo.title} type={workInfo.type} url={workInfo.url} />
            <p className="writer">/ {workInfo.writer}</p>
            {isGoToNews ? (
                <News newsData={newsAll}/>
            ) : (
                <>
                    <div className="workExplain">
                        <p className="workExplainTitle">작품소개</p>
                        <p className="workExplainReal">{workInfo.explain}</p>
                    </div>
                    <div className="news">
                        <span> {newsOne ? `${newsOne.emoji} ${newsOne.title}` : ''}</span>
                        <span className="goingToNews" onClick={changeGoToNews}>
                            {' '}
                            {'>'}{' '}
                        </span>
                        {/* <Link to={`/news/${id}`} style={{ 'text-decoration': 'none' }}>
                            <span className="goingToNews"> {'>'} </span>
                        </Link> */}
                    </div>
                    {getUserBusiness ? (
                        <Business getUserBusiness={getUserBusiness} userInfo={userInfo} changeSuccess={changeSuccess} />
                    ) : (
                        <>
                            <div className="businessUserOrInput">
                                <textarea
                                    className="bussinessInput"
                                    wrap="hard"
                                    onChange={businessPost}
                                    name="content"
                                    value={info}
                                ></textarea>
                                <button className="bussinessBts" onClick={sendInfo}>
                                    영업하기
                                </button>
                            </div>
                            <div className="businessGet"></div>
                        </>
                    )}
                    <div className="allBussiness">
                        {getBusiness &&
                            getBusiness.map((data) => {
                                console.log('data', data);
                                return (
                                    <Business
                                        getUserBusiness={data.business}
                                        userInfo={data.user}
                                        changeSuccess={changeSuccess}
                                    />
                                );
                            })}
                    </div>
                </>
            )}
        </div>
    );
};

export default WorkExplain;
