import React from 'react';
import RecomRes from "./RecomRes";

const RecomResOther = () => {
    return(
        <>
            <RecomRes type='other'/>
        </>
    )
}

export default RecomResOther;