import { Routes, Route } from 'react-router-dom';
import Signup from '../auth/Signup';
import Signin from '../auth/Signin';
import Main from '../pages/Main';
import MyPage from '../pages/MyPage';
import AddRequest from '../work/AddRequest';
import WorkExplain from '../pages/WorkExplain';
import News from '../pages/News';
import BusinessAll from '../pages/BusinessAll';
import ThreadUser from '../thread/ThreadUser';
import ThreadAll from '../thread/ThreadAll';
import MainTemporary from '../ temporary/MainTemporary';
import Password from '../auth/Password';
import MainRecom from '../main/v2/MainRecom';

/**
 * 전체 엔드포인트 집합소
 */
function Middle() {
    return (
            <Routes>

                <Route path="/work/request" element={<AddRequest />} />

                <Route path="/businessShow" element={<BusinessAll />} />

                <Route path="/mypage" element={<MyPage />} />

                <Route path="/signup" element={<Signup />} />
                <Route path="/signin" element={<Signin />} />
                <Route path="/password" element={<Password />} />

                <Route path="/news/:id" element={<News />} />
                <Route path="/business/:id" element={<WorkExplain />} />

                <Route path="/thread/all/:id" element={<ThreadAll />} />
                <Route path="/thread/:id" element={<ThreadUser />} />
                
                <Route path='/' element={<MainRecom />} />
                {/* <Route path='/' element={<MainTemporary />} /> */}
                {/* <Route path="/" element={<Main />} /> */}
            </Routes>
    );
}

export default Middle;
