import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import './Main.css';

const TopAndInput = () => {
    let isAuthorized = sessionStorage.getItem('user');
    const [search, setSearch] = useState('');
    const onChange = (e) => {
        setSearch(e.target.value);
    };

    const [allList, setAllList] = useState([]);
    const filterTitle = allList.filter((p) => {
        return p.title
        .replace(' ', '')
        ?.toLocaleLowerCase()
        .includes(search?.toLocaleLowerCase().replace(' ', ''));
    });
    console.log("filterTitle : ", filterTitle)
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/work`).then((res) => {
        setAllList(res.data.info);
        });
    }, []);
    const goTo = () => {
        !isAuthorized ? window.location.href = '/signin' : window.location.href = '/work/request'
    }
    return (
        <div>
            <div className="title">
                {/* <p>머리 속에 뭉쳐져 있는</p>
                <p>작품들의 타래를 기록해보세요!</p> */}
            </div>
            <div className="inputs">
                <input className="inputs-input" type="text" value={search} onChange={onChange}></input>
                {search !== '' && (filterTitle.length > 0 
                    ? filterTitle.map((list) => {
                        return (
                            <Link to={`/thread/${list._id}`} className="no-underline">
                                <div className='showSearch' key={list._id}>
                                    <p>{list.title}</p>
                                    {/* <p>{list.writer}</p> */}
                                    <p>{list.type}</p>
                                </div>
                            </Link>
                        )
                    }):( <div className='showSearch'>
                            <p>검색결과 없음</p>
                            {/* <p className='goToAddWork' onClick={goTo}>요청하기 {'>'}</p> */}
                        </div>
                    ))
                }
            </div>
        </div>
    );
};

export default TopAndInput;