import React, { useState } from 'react';
import CommentBox from './CommentBox';

/**
 * - 타래 보관함의 view 방식 중 하나
 * - Github 잔디와 같은 모습
 */
const TimesGrid = ({ threadInfo }) => {
    const [selected, setSelected] = useState(null);
    const [position, setPosition] = useState({ top: 0, left: 0 });
    const [animationKey, setAnimationKey] = useState(0); 

    const handleClick = (e, times) => {
        const selectedItems = threadInfo.filter(item => item.times == times);
        if (selectedItems && selectedItems.length > 0) {
            const rect = e.target.getBoundingClientRect();
            const gridRect = e.target.parentElement.getBoundingClientRect();
            console.log("selectedItems: ", selectedItems)
            setSelected({
                times,
                comments: selectedItems.map(item => item.comment), 
            });

            setPosition({
                top: rect.top - gridRect.top - rect.height + 20, 
                left: rect.left + gridRect.left - rect.width
            });

            setAnimationKey(prevKey => prevKey + 1);
        } else {
            setSelected(null);
        }
    };

    const generateGrid = (data) => {
        const maxAllowedTimes = 10000;
        const maxTimes = Math.min(Math.max(...data.map(item => parseInt(item.times, 10)), 0), maxAllowedTimes);

        const gridArray = Array(maxTimes).fill(null); 

        data.sort((a, b) => parseInt(b.times, 10) - parseInt(a.times, 10))
            .forEach((item) => {
                const originalTimes = parseInt(item.times, 10);
                if (originalTimes > 0 && originalTimes <= maxTimes) {
                    gridArray[maxTimes - originalTimes] = item.times; 
                }
            });

        return (
            <div style={{ 
                display: 'grid', 
                gridTemplateColumns: 'repeat(10, 30px)', 
                gap: '5px',
                position: 'relative' 
            }}>
                {gridArray.map((times, index) => (
                    <div
                        key={index}
                        onClick={(event) => handleClick(event, times)}
                        style={{
                            width: '30px',
                            height: '30px',
                            backgroundColor: times ? '#76ABFF' : '#F5F5F5', // times가 있는 경우 파란색, 없으면 회색
                            cursor: 'pointer',
                        }}
                    />
                ))}
            </div>
        );
    };

    return (
        <div style={{ 
            display: 'flex', 
            justifyContent: 'center', 
            alignItems: 'center', 
            position: 'relative',
        }}>
            <div>
                {generateGrid(threadInfo)}
            </div>
            {selected && (
                <CommentBox key={animationKey} times={selected.times} comments={selected.comments} position={position}/>
            )}
        </div>
    );
};


export default TimesGrid;