import React from 'react';
import AuthForm from './AuthForm';

const Signup = () => {
    return (
        <div>
            <AuthForm type='signup' />
        </div>
    );
};

export default Signup;