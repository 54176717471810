import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './recom.css';
import { useNavigate } from 'react-router-dom';

/**
 * 추천 등록하기
 */
const RecomSave = () => {
    const navigate = useNavigate();

    const [inputValue, setInputValue] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [selectedItems, setSelectedItems] = useState([]);
  
    useEffect(() => {
      const fetchResults = async () => {
        if (inputValue.trim() === '') {
          setSearchResults([]);
          return;
        }
  
        try {
          const response = await axios.get(`${process.env.REACT_APP_API_URL}/work/search?query=${inputValue}`);
          setSearchResults(response.data); // 데이터 형식에 따라 조정 필요
        } catch (error) {
          console.error('Error fetching search results:', error);
        }
      };
  
      // 사용자 입력이 있을 때만 검색 결과를 가져옵니다.
      const timerId = setTimeout(() => {
        fetchResults();
      }, 500); // 0.5초 후에 검색 요청 실행
  
      // 클린업 함수를 반환하여 이전의 setTimeout을 취소합니다.
      return () => clearTimeout(timerId);
    }, [inputValue]);
    
    const handleSelectItem = (selectedItem) => {
      setSelectedItems(prevSelectedItems => {
        const exists = prevSelectedItems.some(item => item._id === selectedItem._id);
        if (exists) {
          // 이미 선택된 항목은 선택 해제합니다.
          return prevSelectedItems.filter(item => item._id !== selectedItem._id);
        } else {
          // 새로운 항목을 선택 목록에 추가합니다.
          return [...prevSelectedItems, selectedItem];
        }
      });
    };

    const handleSubmit = async () => {
      // 선택된 항목들의 _id, 추천 이유, 태그를 포함한 데이터를 생성
      const submissionData = selectedItems.map(item => ({
        id: item._id,
        reason: item.reason,
        tags: item.tags
      }));
    
      try {
        // POST 요청으로 선택된 ID들을 백엔드에 전송
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/recom`, { submissionData }, { withCredentials: true }).then(() =>{
          navigate('/recom/res')
        }).catch((err) =>{
        if(err.response.data.code === 444) {
            console.log('hi')
            navigate('/signin', { state: { from: '/recom/save' }})
          }  
        });
        // 응답에 따른 추가 로직 처리
      } catch (error) {
        console.error('Error submitting selected items:', error);
      }
    };

    const [showModal, setShowModal] = useState(false);
    const openModal = () => {
      setShowModal(true);
    };
    
    const closeModal = () => {
      setShowModal(false);
    };

    const [urlInput, setUrlInput] = useState('');
    const [crawlingError, setCrawlingError] = useState(''); // 크롤링 에러 메시지 상태

    const [modalContent, setModalContent] = useState('form');
    const [isLoading, setIsLoading] = useState(false);
    const handleSubmitUrl = async () => {
      setIsLoading(true);
      try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/work/crawling`, { url: urlInput }, { withCredentials: true });
        setIsLoading(false);
        setSelectedItems([...selectedItems, response.data]);
        setModalContent('success'); // 성공 시 모달 내용을 'success'로 변경
      } catch (error) {
        setIsLoading(false);
        setUrlInput('')
        console.error('Error submitting URL:', error);
        setModalContent('error'); // 오류 시 모달 내용을 'error'로 변경
        if(error.response.data.code === 11000) {
          setCrawlingError('이미 등록된 작품입니다.');
        } else if(error.response.data.code === 6000) {
          setCrawlingError('지원하지 않는 플랫폼입니다.');
        } else if(error.response.data.code === 6001) {
          setCrawlingError('지원하지 않는 타입입니다.');
        } else if(error.response.data.code === 444) {
          navigate('/signin', { state: { from: '/recom/save' }})
        }else {
          setCrawlingError('오류가 발생했습니다.');
        }
      }
    };
    
    // 추천이유와 태그 입력하는 컴포넌트로 갈 수 있게 하는 state
    const [showRecommendationInput, setShowRecommendationInput] = useState(false);
    // '다음' 버튼 클릭 핸들러
    const handleNext = () => {
      setShowRecommendationInput(true);
    };
    // 추천 이유와 태그를 업데이트하는 함수
    const handleDetailsChange = (id, field, value) => {
      setSelectedItems(items =>
          items.map(item =>
              item._id === id ? { ...item, [field]: value } : item
          )
      );
  };
  // 각 추천 이유와 태그
  const [currentItemIndex, setCurrentItemIndex] = useState(0);
  const handleNextItem = () => {
      if (currentItemIndex < selectedItems.length - 1) {
          setCurrentItemIndex(currentItemIndex + 1);
      }
  };
    
    
    return (
      <div className='allRecom'>
        

        {!showRecommendationInput ? (
          <>
          <input
            type="text"
            placeholder="제목을 입력하세요"
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
          />
          <hr />
          {searchResults.length > 0 && (
          <ul>
            {searchResults.map((result, index) => (
              <li
              key={result._id}
              className={`list-item ${selectedItems.includes(result._id) ? 'selected' : ''}`}
              onClick={() => handleSelectItem(result)}
            >
              <span>{result.title}</span>
              <span>{result.type}</span>
            </li>
            ))}
          </ul>
        )}
          <div className="selected-items-container">
            {selectedItems.length > 0 && (
              <>
                <div>선택된 항목</div>
                <ul>
                  {selectedItems.map((item) => (
                    <li
                    key={item._id}
                    className="list-item"
                  >
                    <span>{item.title}</span>
                    <span>{item.type}</span>
                  </li>
                  ))}
                </ul>
              </>
            )}
             <button className='save-request-button' onClick={handleNext}>다음</button>
          </div>
          </>
        ) : (
          selectedItems.length > 0 && currentItemIndex < selectedItems.length && (
            <div key={selectedItems[currentItemIndex]._id}>
                <div className='recomTilteAndType'>
                  <h2>{selectedItems[currentItemIndex].title}</h2>
                  <p>{selectedItems[currentItemIndex].type}</p>
                </div>
                
                <div className='recomPAndTextarea'>
                  <p>추천 이유</p>
                  <textarea
                      className='recomPAndTextarea-textarea'
                      value={selectedItems[currentItemIndex].reason || ''}
                      onChange={(e) => handleDetailsChange(selectedItems[currentItemIndex]._id, 'reason', e.target.value)}
                  />
                </div>
                
                <div className='recomPAndInput'>
                  <p>태그</p>
                  <input
                      type="text"
                      placeholder="쉼표로 구분"
                      value={selectedItems[currentItemIndex].tags || ''}
                      onChange={(e) => handleDetailsChange(selectedItems[currentItemIndex]._id, 'tags', e.target.value)}
                  />
                </div>
                
                {currentItemIndex < selectedItems.length - 1 ? (
                    <button className='save-request-button' onClick={handleNextItem}>다음</button>
                ) : (
                    <button className='save-request-button' onClick={handleSubmit}>완료</button>
                )}
            </div>
        ) 
      )}
        
        <div className='save-div' onClick={openModal}>작품 등록하기 {'>'}</div>
        {showModal && (
          <div className="modal">
            <div className="modal-content">
              <span className="close" onClick={() => { closeModal(); setModalContent('form'); }}>&times;</span>
              {modalContent === 'form' && (
                <>
                  {isLoading ? <h2>크롤링 하는 중</h2> : <h2>URL 입력하기</h2>}
                  {isLoading ? <p>잠시만 기다려주세요</p> : <>
                    <input
                      type="text"
                      placeholder="URL을 입력하세요"
                      value={urlInput}
                      onChange={(e) => setUrlInput(e.target.value)}
                    />
                    <button onClick={handleSubmitUrl} disabled={!urlInput}>제출</button>
                  </>}
                  
                  
                </>
              )}
              {modalContent === 'error' && <p>{crawlingError}</p>}
              {modalContent === 'success' && <p>작품이 성공적으로 등록되었습니다!</p>}
            </div>
          </div>
        )}
      </div>
    );
  }
  

export default RecomSave;