import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { checkAuth } from "../check";

/**
 * - 로그인이 안되어져 있으면 나타나는 아이
 * - 로그인 되어져 있으면 나타나지 않음
 */
const SigninCheck = () => {
    const [isAuthorized, setIsAuthorized] = useState(null);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const checkAuthorization = async () => {
            const authorized = await checkAuth();  // 로그인 여부를 확인하는 함수
            setIsAuthorized(authorized);
            setLoading(false);  // 로딩 상태 업데이트

            if (authorized && location.state?.from && !sessionStorage.getItem('reloaded')) {
                sessionStorage.setItem('reloaded', 'true');
                window.location.reload();
            }
        };

        checkAuthorization();
    }, [location]);

    const handleButtonClick = () => {
        navigate('/signin', { state: { from: location.pathname } });
    };

    if (loading) {
        return <div>Loading...</div>; // 로딩 중일 때 표시
    }

    if (!isAuthorized) {
        return (
            <div style={{ display: 'flex', justifyContent: 'flex-end', padding: '10px' }}>
                <div
                    onClick={handleButtonClick}
                    style={{
                        cursor: 'pointer',
                        color: '#76ABFF',
                        fontWeight: 'bold',
                    }}
                >
                    로그인
                </div>
            </div>
        );
    }

    return null; // 로그인된 상태라면 아무것도 렌더링하지 않음
};


export default SigninCheck;
