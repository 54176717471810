import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './index.css';
import Middle from './positions/Middle';
import Top from './positions/Top';
import Recom from './recom/recom';
import RecomReq from './recom/RecomReq';
import RecomSave from './recom/RecomSave';
import RecomResOther from './recom/RecomResOther';
import RecomResUser from './recom/RecomResUser';
import Admin from './admin/admin';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(

        <BrowserRouter>
          <Routes>
            {/* /recom 경로에는 Top 컴포넌트 없이 Recom 컴포넌트만 렌더링 */}
            <Route path="/recom" element={<Recom />} />
            <Route path='/recom/res/:id' element={<RecomResOther />} />
            <Route path='/recom/res' element={<RecomResUser />} />
            <Route path='/recom/req' element={<RecomReq />} />
            <Route path='/recom/save' element={<RecomSave />}/>

            {/* 어드민 */}
            {/* <Route path="/admin" element={<Admin />} /> */}

            {/* 나머지 경로에는 Top 컴포넌트와 Middle 컴포넌트를 렌더링 */}
            {/* 이 Route는 Middle 컴포넌트 내부의 라우트들을 위한 Outlet 컴포넌트를 렌더링합니다 */}
            <Route path="*" element={<><Middle /></>} />
          </Routes>
        </BrowserRouter>

);

