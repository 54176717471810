import React, { useEffect, useState, useRef } from "react";
import RecomTop from "./RecomTop";
import axios from "axios";
import { errorHandler } from "../check";
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import './recom.css';
import RecomSubmit from "./RecomSubmit";

const RecomRes = ({ type }) => {
    const navigate = useNavigate();

    // 새로고침을 위한 상태값
    const [refreshData, setRefreshData] = useState(false);

    const [works, setWorks] = useState([]);
    
    const [url, setUrl] = useState('');
    const [currentWorkIndex, setCurrentWorkIndex] = useState(null);

    let { id } = useParams();
    const location = useLocation();

    // 웹툰, 웹소설 필터링
    const [filterType, setFilterType] = useState('웹툰'); 
    const changeFilterType = (newFilterType) => {
        setFilterType(newFilterType);
        setSelectedTag('');
      };

    useEffect(() => {
        const fetchUrl = type === 'user' ? `${process.env.REACT_APP_API_URL}/recom` : `${process.env.REACT_APP_API_URL}/recom/${id}`;
        
        const queryParams = new URLSearchParams(location.search);
        const tags = queryParams.get('tags');
        const params = tags ? { params: { tags } } : {};
        
        axios.get(fetchUrl, { ...params, withCredentials: true }).then((res) => {
            console.log("res.data.workList: ", res.data)
            setWorks(res.data.workList);
            setUrl(res.data.url);
        }).catch((err) => errorHandler(err, navigate, '/recom/res'));
    }, [type, id, navigate, refreshData, filterType]);

    const [showModal, setShowModal] = useState(false);
    const [currentWork, setCurrentWork] = useState(null);
    const [userFeedback, setUserFeedback] = useState({
        read: false,
        rating: '',
        addToRecommendation: false
    });

    const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false);
    const [workToDelete, setWorkToDelete] = useState(null);
    const handleDeleteClick = (work) => {
        setWorkToDelete(work.id);
        setShowDeleteConfirmModal(true);
      };

    const handleImageClick = async (index) => {
        const selectedWork = filteredWorksByTag[index];
        if (selectedWork) {
            setCurrentWork(selectedWork);
            setCurrentWorkIndex(index);
            setShowModal(true);
        }
    };

    // 모달 헨들링을 위한 함수
    const handleNextPrevWork = (direction) => {
        const newIndex = direction === 'next' ? currentWorkIndex + 1 : currentWorkIndex - 1;
        if (newIndex >= 0 && newIndex < filteredWorksByTag.length) {
            setCurrentWork(filteredWorksByTag[newIndex]);
            setCurrentWorkIndex(newIndex);
        }
    };

    const submitFeedback = async (feedback) => {
        try {
            await axios.post(`${process.env.REACT_APP_API_URL}/recom/feedback`, {
                workId: currentWork.id._id,
                feedback: feedback
            }, { withCredentials: true });
            setShowModal(false);
            setUserFeedback({
                read: false,
                rating: '',
                addToRecommendation: false
            });
        } catch (error) {
            errorHandler(error, navigate, `/recom/res/${id}`)
        }
    };

    const handleRating = (rating) => {
        setUserFeedback({ ...userFeedback, rating: rating });
        // 나쁜 평가를 받으면 서버에 피드백을 바로 보내고 모달을 닫습니다.
        if (rating === 'bad') {
            submitFeedback({ ...userFeedback, rating, addToRecommendation: false });
            setShowModal(false);
        }
    };

    const handleRecommendation = (addToRec) => {
        setUserFeedback({ ...userFeedback, addToRecommendation: addToRec });
        submitFeedback({ ...userFeedback, addToRecommendation: addToRec });
    };

    const closeModal = () => {
        setIsEditing(false);
        setShowOptions(false);
        setShowModal(false);
        setUserFeedback({
            read: false,
            rating: '',
            addToRecommendation: false
        });
    };

    const filteredWorks = works.filter(work => work.id.type === filterType);

    // 선택된 태그를 저장하는 변수
    const [selectedTag, setSelectedTag] = useState('');
    const tagClick = (tag) => {
        setSelectedTag(tag);
    }
    const filteredWorksByTag = selectedTag
        ? works.filter(work => work.tags.includes(selectedTag))
        : filteredWorks;

    
    const combinedTags = Array.from(filteredWorks.reduce((accumulator, work) => {
        work.tags.forEach(tag => accumulator.add(tag));
        return accumulator;
    }, new Set()));
    

    // 모달 바깥을 누를 경우 모달 없어지기
    useEffect(() => {
        // 모달 바깥 클릭 감지 함수
        const handleClickOutside = (event) => {
            const modalElement = document.querySelector('.modal-content');
            // 클릭한 요소가 모달이 아니고 모달의 자식 요소도 아닌 경우
            if (modalElement && !modalElement.contains(event.target)) {
                closeModal();
            }
        };
    
        // 모달이 활성화되어 있을 때 이벤트 리스너를 추가
        if (showModal || showDeleteConfirmModal) {
            document.addEventListener('mousedown', handleClickOutside);
        }
    
        // 컴포넌트 언마운트 시 이벤트 리스너 제거
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [showModal, showDeleteConfirmModal, closeModal]);

    // 수정버튼, 삭제버튼
    const [showOptions, setShowOptions] = useState(false);
    const toggleOptions = () => setShowOptions(!showOptions);

    // 수정 시
    const [isEditing, setIsEditing] = useState(false);
    const [editedReason, setEditedReason] = useState('');
    const [editedTags, setEditedTags] = useState([]);
    const enterEditMode = () => {
        setShowOptions(false);
        setIsEditing(true);
        setEditedReason(currentWork.reason);
        setEditedTags(currentWork.tags.join(', ')); // 배열을 문자열로 변환
      };
    const handleSave = async () => {
        // 수정된 데이터를 백엔드에 전송
        const updatedData = {
          reason: editedReason,
          tags: editedTags.split(',').map(tag => tag.trim()),
          id: currentWork.id._id
        }

        try {
            await axios.put(`${process.env.REACT_APP_API_URL}/recom`, updatedData, { withCredentials: true });     
            
            setIsEditing(false);
            setRefreshData(prev => !prev);
          
            closeModal();
        } catch (error) {
          console.error("Failed to update work", error);
          // 에러 처리
        }
    };
    
    // 삭제시
    const confirmDelete = async () => {
        try {
            await axios.delete(`${process.env.REACT_APP_API_URL}/recom/${currentWork.id._id}`, { withCredentials: true });
            setIsEditing(false);
            setRefreshData(prev => !prev);
          
            closeModal();
        } catch (error) {
          errorHandler(error, navigate, `/recom/res/${id}`);
        }
    };


    return (
        <div className="allRecom">
            <RecomTop url={url} type={type} tags={combinedTags}/>
            <div className="filter-buttons">
                <button className={filterType === '웹툰' ? 'active' : ''} onClick={() => changeFilterType('웹툰')}>웹툰</button>
                <button className={filterType === '웹소설' ? 'active' : ''} onClick={() => changeFilterType('웹소설')}>웹소설</button>
            </div>
            <div className="recom-res-tags">
                    {combinedTags.map((tag, index) => {
                        return <p key={index} className="recom-res-tags-one" onClick={() => tagClick(tag)}>#{tag}</p>;
                    })}
            </div>
            <div className="recom-res-container">
            {filteredWorksByTag.map((work, index) => (
                <div key={work.id._id} className="work-item" style={{ backgroundColor: '#ccc' }}>
                    {work.hasFeedback && <div className="overlay"></div>}
                    <img 
                    src={work.id.image && work.id.image[0]} 
                    onClick={() => handleImageClick(index)}
                    alt={work.id.title} 
                    />
                </div>
            ))}
            </div>
            {showModal && (
                <div className="modal">
                    <div className="modal-content">
                    {!userFeedback.read ? (<>
                        {!isEditing && (
                            <div className="modal-navigation">
                            {currentWorkIndex > 0 && (
                                <div className="modal-navigation-left" onClick={() => handleNextPrevWork('prev')}>
                                    <span>{"<"}</span>
                                </div>
                            )}
                            {currentWorkIndex < works.length - 1 && (
                                <div className="modal-navigation-right" onClick={() => handleNextPrevWork('next')}>
                                    <span>{">"}</span>
                                </div>
                            )}
                         </div>
                        )}
                            <div className="modal-content-titleAndType">
                                <div className="title-and-dots">
                                    <h2 className="modal-content-titleAndType-title">{currentWork.id.title}</h2>
                                    
                                    {!isEditing && type === 'user' && <p className="dots" onClick={toggleOptions}>...</p>}
                                    {showOptions && (
                                        <div className="dots-options-container">
                                            <div className="option" onClick={() => enterEditMode()}>수정</div>
                                            <div className="option" onClick={() => confirmDelete()}>삭제</div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="modal-content-reason">
                                <h4>추천 이유</h4>
                                {isEditing ? (
                                    <textarea
                                    className="modal-content-reason-textarea"
                                    value={editedReason}
                                    onChange={(e) => setEditedReason(e.target.value)}
                                    />
                                ) : (
                                    <p className="modal-content-reason-reason">{currentWork.reason}</p>
                                )}
                            </div>
                            <div className="modal-content-tags">
                                <h4>태그</h4>
                                {isEditing ? (
                                <input
                                    className="modal-content-tags-input"
                                    type="text"
                                    value={editedTags}
                                    onChange={(e) => setEditedTags(e.target.value)}
                                    />
                                ) : (
                                    <>
                                        {currentWork.tags.map((tag, index) => (
                                            <p key={index} className="modal-content-tags-tag">#{tag}</p>
                                        ))}
                                    </>
                                    
                                )}
                            </div>
                           
                            {isEditing ?
                             <button onClick={handleSave} className='isEditing-save-request-button'>완료</button>
                            :(  
                                <>
                                    <div className="modal-content-platform">
                                        <h4>플랫폼</h4>
                                        {currentWork.id.platform.map((work, index) => (
                                            <a key={index} href={work.url} className="modal-content-platform-name">{work.name}</a>
                                        ))}
                                    </div>
                                    {type !== 'user' && <div className="modal-content-option">
                                        {currentWork.hasFeedback && type !== 'user'
                                            ?   <h4>이미 본 작품</h4>
                                            :   <>
                                                <h4>아직 안 본 작품</h4>
                                                <p onClick={() => setUserFeedback({...userFeedback, read: true})}>평가하기</p>
                                            </>
                                        }
                                </div>}
                                </>
                                
                            )}
                        </>) : (<>
                            {!userFeedback.read && currentWork && !currentWork.hasFeedback && (
                                <>
                                    <p>이 작품을 읽었나요?</p>
                                    <button className="modal-content-button-left" onClick={() => setUserFeedback({...userFeedback, read: true})}>예</button>
                                    <button className="modal-content-button-rigth" onClick={closeModal}>아니오</button>
                                </>
                            )}
                            {userFeedback.read && !userFeedback.rating && (
                                <>
                                    <p>작품을 평가해주세요</p>
                                    <button className="modal-content-button-left" onClick={() => handleRating('good')}>👍</button>
                                    <button className="modal-content-button-rigth" onClick={() => handleRating('bad')}>👎</button>
                                </>
                            )}
                            {/* '좋음'을 눌렀을 때만 추천함에 추가할지 묻습니다. */}
                            {userFeedback.read && userFeedback.rating === 'good' && (
                                <>
                                    <p>추천함에 추가할까요?</p>
                                    <button className="modal-content-button-left" onClick={() => handleRecommendation(true)}>동의</button>
                                    <button className="modal-content-button-rigth" onClick={() => handleRecommendation(false)}>취소</button>
                                </>
                            )}
                        </>)
                    }
                    </div>
                </div>
            )}
            <RecomSubmit />
        </div>
    )}

export default RecomRes;