import axios from 'axios';
import React, { useState } from 'react';
import './Work.css'

const AddRequest = () => {
    const [workData, setWorkData] = useState({
        title: '',
        writer: '',
        type: ''
    });
    const onChange = (e) => {
        setWorkData({
            ...workData,
            [e.target.name]: e.target.value,
        })
    }
    const goToMain = () => {
        axios.post(`${process.env.REACT_APP_API_URL}/work/request`, workData, { withCredentials: true })
            .then((res) => {
                console.log(res)
                resultAlert('요청에 성공하였습니다.')
                setWorkData({
                    title: '',
                    writer: '',
                    type: ''
                })
                window.location.href = "/"
            }).catch((err) => {
                resultAlert('요청에 실패하였습니다.')
                console.log(err);
            })
    }
    const resultAlert = (message) => {
        alert(message)
    }
    return (
        <div>
            <div className='top'>
                <p>작품 요청하기</p>
            </div>
            <div className='middle'>
                <input placeholder='제목' name='title' value={workData.title} onChange={onChange}></input>
                <input placeholder='작가' name='writer' value={workData.writer} onChange={onChange}></input>
                <input placeholder='유형' name='type' value={workData.type} onChange={onChange}></input>
                <p>작품을 요청해주실 경우 등록하기까지 3~7일 정도 소요될 수 있습니다. 등록이 완료된 경우 이메일 또는 알림을 발송해드리겠습니다.</p>
            </div>
            <div className='bottom'>
                <button onClick={goToMain}>요청</button>
            </div>
        </div>
    );
};

export default AddRequest;