import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './recom.css';
import axios from "axios";
import { errorHandler } from '../check';

/**
 * 모든 곳에서 사용되는 상단
 */
const RecomTop = ({ url, type, tags }) => {
    const navigate = useNavigate();
    const actionsByType = {
      user: ["보관함", "공유", "등록"],
      other: ["보관함", "저장"],
      req: ["요청", "추천함"]
    };
    const currentActions = actionsByType[type] || [];
    
    const [selectedTags, setSelectedTags] = useState([]);
    const [showTagModal, setShowTagModal] = useState(false);
    const [showShareModal, setShowShareModal] = useState(false);
    const [showSaveModal, setShowSaveModal] = useState(false);
    const [showRequestModal, setShowRequestModal] = useState(false)

    const selectedTagsQueryString = selectedTags.map(tag => encodeURIComponent(tag)).join(',');
    const urlResult = `${process.env.REACT_APP_F_API_URL}/recom/res/${url}?tags=${selectedTagsQueryString}`;
    const urlResultResult = `${process.env.REACT_APP_F_API_URL}/recom`
    const initialActionText = actionsByType[type] ? actionsByType[type][actionsByType[type].length - 1] : "";
    const [activeText, setActiveText] = useState(initialActionText);
    
    const [name, setName] = useState('');

    // 사용자의 URL을 불러옵니다.
    const [urlDataSate, setUrlDataState] = useState(false);
    const findURL = async () => {
      const backData = await axios.get(`${process.env.REACT_APP_API_URL}/recom/url`, { withCredentials: true });
      console.log("backData:" , backData)
      if(backData.data.info) {
        setUrlDataState(false);
      } else{
        setUrlDataState(true)
      }
    }




    const handleDotClick = (text) => {
        setActiveText(text);
      };
    const handleAction = async () => {
        // activeText의 값에 따라 서버 요청을 합니다.
        if (!activeText) return; // 텍스트가 없으면 아무것도 하지 않습니다.
    
        switch (activeText) {
          case "공유":
            findURL();
            setShowTagModal(true);
            break;
          case "요청":
            setShowRequestModal(true)
            break;
          case "등록":
            navigate('/recom/save')
            break;
          case "보관함":
            navigate('/recom/req')
            break;
          case "저장":
            setShowSaveModal(true);
            break;
          case "추천함":
            navigate('/recom/res')
              break;
          case "삭제":
            
              break;
          default:
            console.log("알 수 없는 동작입니다.");
        }
    };
    
    const handleNextInTagModal = () => {
      setShowTagModal(false);
      setShowShareModal(true); 
    };

    const closeModal = () => {
      setShowShareModal(false);
      setShowSaveModal(false);
      setShowRequestModal(false)
      setModalContent({
        title: '보관함',
        p: '보관함 이름을 설정해주세요',
        buttonText: '저장',
        inputPlaceholder: '이름을 입력해주세요',
        isInputVisible: true,
      });
      setName('');
      setSelectedTags([])
    };

    const [modalContent, setModalContent] = useState({
      title: '보관함',
      p: '보관함 이름을 설정해주세요',
      buttonText: '저장',
      inputPlaceholder: '이름을 입력해주세요',
      isInputVisible: true,
    });

    const saveName = async () => {
      try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/recom/locker`, {
          name: name,
          url: url
        }, { withCredentials: true });
        
        setModalContent({
          ...modalContent,
          title: '성공',
          isInputVisible: false,
          p: '보관함에 저장되었습니다'
        });
      } catch (error) {
        errorHandler(error, navigate, `/recom/res/${url}`)
        if(error.response.data.code === 10 ){
          setModalContent({
            ...modalContent,
            title: '실패',
            isInputVisible: false,
            p: '보관함에 저장할 수 있는 최대 갯수는 10개입니다'
          });
        } else { 
          setModalContent({
            ...modalContent,
            title: '실패',
            isInputVisible: false,
            p: '저장에 실패하였습니다.'
          });
        }
        
      }
    };

    const handleTagSelect = (selectedTag) => {
      setSelectedTags(prevSelectedTags =>
        prevSelectedTags.includes(selectedTag)
          ? prevSelectedTags.filter(tag => tag !== selectedTag)
          : [...prevSelectedTags, selectedTag]
      );
    };

    return (
      <>
        <div className="dot-navigation">
          {currentActions.map(actionText => (
            <span className={activeText === actionText? 'active' : ''} key={actionText} onClick={() => handleDotClick(actionText)}></span>
          ))}
          <div onClick={handleAction}>{activeText}</div>
        </div>
        {urlDataSate && (
          <div className="modal">
            <div className="modal-content">
              <span className="close" onClick={() => setShowTagModal(false)}>&times;</span>
              <h2>URL 생성</h2>
              <p className='modal-content-text'>공유하기 전에 태그를 선택해주세요</p>
              <div className='modal-content-tag-container'>
                {tags.map((tag, index) => (
                  <p key={index} onClick={() => handleTagSelect(tag)} className={selectedTags.includes(tag) ? 'selected' : ''}>
                    #{tag}
                  </p>
                ))}
              </div>
              <button className='save-request-button' onClick={handleNextInTagModal}>다음</button>
            </div>
          </div>
        )}
        {showTagModal && (
          <div className="modal">
            <div className="modal-content">
              <span className="close" onClick={() => setShowTagModal(false)}>&times;</span>
              <h2>태그 선택</h2>
              <p className='modal-content-text'>공유하기 전에 태그를 선택해주세요</p>
              <div className='modal-content-tag-container'>
                {tags.map((tag, index) => (
                  <p key={index} onClick={() => handleTagSelect(tag)} className={selectedTags.includes(tag) ? 'selected' : ''}>
                    #{tag}
                  </p>
                ))}
              </div>
              <button className='save-request-button' onClick={handleNextInTagModal}>다음</button>
            </div>
          </div>
        )}

        {showShareModal && (
          <div className="modal">
            <div className="modal-content">
              <span className="close" onClick={closeModal}>&times;</span>
              <h2>공유</h2>
              <p>복사를 클릭해 아래 URL을 공유하세요</p>
              <input className="modal-content-url" type="text" readOnly value={urlResult} />
              <button className='save-request-button' onClick={() => { navigator.clipboard.writeText(urlResult); closeModal(); }}>복사</button>
            </div>
          </div>
        )}
        {showRequestModal && (
          <div className="modal">
            <div className="modal-content">
              <span className="close" onClick={closeModal}>&times;</span>
              <h2>요청</h2>
              <p>아래 URL을 복사해 추천을 요청해보세요!</p>
              <input type="text" readOnly value={urlResultResult} />
              <button className='save-request-button' onClick={() => { navigator.clipboard.writeText(urlResultResult); closeModal(); }}>복사</button>
            </div>
          </div>
        )}
        {showSaveModal && (
          <div className="modal">
            <div className="modal-content">
              <span className="close" onClick={closeModal}>&times;</span>
              <h2>{modalContent.title}</h2>
              <p>{modalContent.p}</p>
              {modalContent.isInputVisible && (
                <input
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  placeholder={modalContent.inputPlaceholder}
                />
              )}
              {modalContent.isInputVisible && (
                <button className='save-request-button' onClick={saveName}>{modalContent.buttonText}</button>
              )}
            </div>
          </div>
        )}
      </>
        
    )
}

export default RecomTop;