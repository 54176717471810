import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import '../thread/Thread.css';
import ThreadAndWorkExplain from './ThreadAndWorkExplain';
import ThreadSave from './ThreadSave';
import ThreadSearch from './ThreadSearch';
import TimesGrid from './TimesGrid';
import SinginCheck from '../auth/\bSigninCheck';

const Thread = ({ type }) => {
    const { id } = useParams();
    const [threadType, setThreadType] = useState(type);
    const [workInfo, setWorkInfo] = useState({});
    const [threadInfo, setThreadInfo] = useState([]);
    const [listType, setListType] = useState('oldest');  // listType 상태 유지

    useEffect(() => {
        if (threadType === 'user') {
            axios.get(`${process.env.REACT_APP_API_URL}/thread/${id}`, { withCredentials: true }).then((res) => {
                setWorkInfo(res.data.info.work);
                setThreadInfo(res.data.info.thread);
            });
        }
        if (threadType === 'all') {
            axios.get(`${process.env.REACT_APP_API_URL}/thread/all`, {
                params: {
                    work: id,
                    type: listType,
                },
                withCredentials: true,
            }).then((res) => {
                setWorkInfo(res.data.info.work);
                setThreadInfo(res.data.info.thread);
            });
        }
    }, [id, threadType, listType]);

    const navigate = useNavigate();
    const onClinkGoToTheType = (type) => {
        if (type === 'user') {
            setThreadType('user');
            navigate(`/thread/${id}`, { replace: true });
        }
        if (type === 'all') {
            setThreadType('all');
            navigate(`/thread/all/${id}`, { replace: true });
        }
    };

    return (
        <>
            <div className="threadDivAll">
                <SinginCheck />
                <div className="threadTop">
                    <ThreadAndWorkExplain title={workInfo.title} id={workInfo._id} type={workInfo.type} />
                    <p className="threadTopText">타래 {threadType === 'all' ? '전체' : threadType === 'detail' ? '자세히' : ''} 보관함</p>
                    <div className="status-indicator">
                        <span className={`dot ${threadType === 'user' ? 'colordot' : ''}`} onClick={() => { onClinkGoToTheType('user') }} id="dot1"></span>
                        <span className={`dot ${threadType === 'all' ? 'colordot' : ''}`} onClick={() => { onClinkGoToTheType('all') }} id="dot2"></span>
                        {threadType === 'detail' && <span className="dot colordot" id="dot3"></span>}
                    </div>
                </div>
                {threadType === 'user' && <ThreadSave id={id} />}
                {threadType === 'all' && <ThreadSearch id={id} />}

            </div>

            <TimesGrid threadInfo={threadInfo} />

            {/* <ThreadListAll
                threadInfo={threadInfo}
                threadType={threadType}
                id={id}
                listType={listType}  // listType prop으로 전달
                setThreadInfo={setThreadInfo}
            /> */}
        </>
    );
};

export default Thread;
