import React, { useRef, useEffect, useState } from 'react';

/**
 * - 그리드 중 하나의 정사각형을 누르면 나오는 코멘트
 * - 코멘트가 없는 정사각형의 경우 "x화 코멘트 작성해보세요"라는 문구가 나와야 함
 */
const CommentBox = ({ times, comments, position }) => {
    const [heights, setHeights] = useState([]);
    const refs = useRef([]);

    useEffect(() => {
        const newHeights = refs.current.map(ref => ref ? ref.getBoundingClientRect().height : 0);
        setHeights(newHeights);
    }, [comments]);

    if (!times) return null;

    return (
        <>
            {comments.map((comment, index) => (
                <div
                    key={index}
                    ref={el => refs.current[index] = el}
                    style={{
                        position: 'absolute',
                        top: `${position.top + heights.slice(0, index).reduce((acc, h) => acc + h + 5, 0)}px`, // 각 댓글의 높이를 고려한 top 계산
                        left: `${position.left}px`,
                        backgroundColor: '#FFFFFF',
                        border: '1px solid #000',
                        borderRadius: '8px',
                        padding: '8px 12px',
                        boxShadow: '0 0 5px rgba(0, 0, 0, 0.1)',
                        zIndex: 10000,
                        fontSize: '12px',
                        maxWidth: '220px',
                        maxHeight: '150px',
                        overflow: 'auto',
                        whiteSpace: 'normal',
                        wordWrap: 'break-word',
                        lineHeight: '1.5',
                        letterSpacing: 'normal',
                        opacity: 0,
                        transform: `translateY(-20px)`,
                        animation: `slideDown 0.3s ease forwards`,
                        animationDelay: `${index * 0.2}s`
                    }}
                >
                    <p style={{ margin: 0 }}>
                        <strong>{times}</strong> {'>'} {comment}
                    </p>
                </div>
            ))}
            <style>
                {`
                    @keyframes slideDown {
                        to {
                            opacity: 1;
                            transform: translateY(0);
                        }
                    }
                `}
            </style>
        </>
    );
};


export default CommentBox;
